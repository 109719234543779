import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/Frontend/ListingsFilter/redux/slice";
import { NextSeo } from "next-seo";
import { selectSiteSettingForm } from "../redux/Admin/SettingSection/SiteSetting/redux/selector";
import { path } from "../utils/Api";
import { log } from "../utils/logger";
function ErrorPage({ title, description }: any) {
  log(title, description, "StaticProps");
  const router = useRouter();
  const dispatch = useDispatch();
  const _handleroute = (activityFilter: any) => {
    let qr = { activityFilter };
    !qr.activityFilter && delete qr.activityFilter;
    if (qr.activityFilter?.length > 0) {
      dispatch(actions.setActivityList(qr.activityFilter));
    }
    router.push({
      pathname: "/listing",
      query: qr,
    });
  };
  const photo = useSelector(selectSiteSettingForm);
  const currentUrl = router.asPath;
  return (
    <div>
      <NextSeo
        canonical={currentUrl}
        title={title}
        description={description}
        openGraph={{
          url: router.pathname,
          title: "There Can Be Glitches & We Appreciate Your Patience. ",
          description:
            "Errors and glitches can occur anytime but we promise a comeback at the earliest. In case of Error 404, feel free to surf your favorite retreats during repair. ",
          images: [{ url: `${path()}${photo.logo}` }],
          siteName: "Yoga Retreat",
        }}
      />
      <div
        className="container d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="row page404">
          <div className="col-lg-7 col-12  ">
            <h2>Oops! We Think Something Went Wrong</h2>
            <h4 className="mt-3">404 Error</h4>
            <h5 className="mt-4">
              Miss Kitty says, don’t panic. She wants you to relax, take a deep
              breath & grab some snacks!
            </h5>
            <p className="my-4">Maybe you can find what you need here! </p>
            <div className="position-absolute d-none d-sm-flex ArrowImage">
              <img src="/images/arrow.png" className="img-fluid " alt="" />
            </div>
            <div className="d-sm-flex d-none  flex-wrap text-sm-start">
              <p onClick={() => _handleroute("Yoga Retreat")}>
                <button className="btn btn-primary btnHover fs-13 py-2 fw-500">
                  View all the Retreats
                </button>
              </p>
              <p onClick={() => _handleroute("Yoga Teacher Training")}>
                <button className="btn btn-primary btnHover fs-13 py-2 ms-2 ms-sm-2 fw-500 mt-2 mt-sm-0">
                  View all Teacher Training
                </button>
              </p>
            </div>
            <div className="d-sm-none d-block text-center">
              <p onClick={() => _handleroute("Yoga Retreat")}>
                <button className="btn btn-primary btnHover fs-13 py-2 fw-500 w-100">
                  View all the Retreats
                </button>
              </p>
              <p onClick={() => _handleroute("Yoga Teacher Training")}>
                <button className="btn btn-primary btnHover fs-13 py-2 fw-500 mt-2 mt-sm-0 w-100">
                  View all Teacher Training
                </button>
              </p>
            </div>
            <p className="mt-3">-or-</p>
            <div>
              <Link href={"/"}>
                <button className="btn btnHover py-2 fw-semibold fs-13 border">
                  BACK TO HOME
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5 col-12 mt-5 mt-lg-0 justify-content-lg-end d-flex justify-content-center align-items-center">
            <img
              src="/images/404 Error.png"
              className="img-fluid ErrorImage"
              alt=""
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;

export async function getStaticProps() {
  const title = "There Can Be Glitches & We Appreciate Your Patience. ";
  const description =
    "Errors and glitches can occur anytime but we promise a comeback at the earliest. In case of Error 404, feel free to surf your favorite retreats during repair. ";
  return {
    props: {
      title,
      description,
    },
  };
}
